@import 'styles/_vars';

$container-vertical-padding: 20px;
$container-horizontal-padding: 40px;
$desktop-logo-height: 70px;
$desktop-width: 1024px;
$desktop-header-height: $desktop-logo-height + $container-vertical-padding * 2;
$mobile-logo-height: 50px;
$mobile-header-height: $mobile-logo-height + $container-vertical-padding * 2;

.header {
  height: $mobile-header-height;
  display: flex;
  padding: $container-vertical-padding;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  @media (min-width: $desktop-width) {
    padding: $container-vertical-padding 40px;
    height: $desktop-header-height;
  }

  &__logo {
    height: $mobile-logo-height;

    @media (min-width: $desktop-width) {
      height: $desktop-logo-height;
    }
  }

  &__menu {
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    padding: 0;

    .language-switcher {
      padding: 0;
      cursor: pointer;

      &__lang {
        &--active {
          color: $blue;
        }

        &:hover {
          background-color: transparent;
          color: $blue;
        }
      }

      @media (min-width: $desktop-width) {
        padding: 10px;
      }
    }
  }
}
