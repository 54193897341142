@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Poiret+One&display=swap');
@import 'styles/_vars';

html,
body,
#root {
  height: 100%;
}

body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  font-weight: 300;
  color: $gray;
}

#root {
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
p {
  line-height: 1.5em;
}

a {
  color: $blue;
}
