.craniosacraltherapy {
  &__image {
    width: 100%;
    margin: 50px auto 0;
    display: block;

    @media (min-width: 768px) {
      width: 50%;
    }
  }

  p:first-child {
    margin-top: 0;
  }
}
