@import 'styles/_vars';

.hero {
  font-family: 'Poiret One', cursive;
  background: url('./hero.jpg') no-repeat top left;
  background-size: cover;
  height: 250px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    font-size: 1.5em;
    line-height: 1.25em;
    text-align: center;
    color: $white;
    margin: 0 20px;
    padding: 0;

    @media (min-width: 768px) {
      font-size: 1.7em;
      margin: 0;
    }
  }
}
