@import 'styles/_vars';

.app {
  flex: 1 0 auto;

  &__content {
    padding: 30px;

    @media (min-width: 768px) {
      padding: 40px 120px;
    }
  }
}
